.cloud-pricing {
	.heading {
		h1, h2, h3 {
			font-size: 28px;
			margin-bottom: 40px;
			text-align: center;

			em, i, b, span {
				font-size: 28px;
				color: var(--color-blue-light);
			}
		}

		ol {
			margin-bottom: 20px;
			flex-direction: column;
			gap: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				display: flex;
				align-items: center;
				&::before {
					content:'';
					width: 12px;
					height: 12px;
					border-radius: 100px;
					background: var(--color-blue-dark);
					margin-right: 5px;
					float: left;
				}
			}
		}
	}

	small {
		color: #ccc;
		display: block;
		font-size: 12px;
		text-align: center;
		max-width: 600px;
		padding-left: 10px;
		padding-right: 10px;
		margin: 0 auto 20px auto;
	}

	.card-module {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 0 10px;

		.card-container {
			padding: 0 10px;

			article {
				background: var(--color-gray);
				border-radius: 4px;
				box-shadow: 0 4px 4px rgba(#2862A5, 0.25);
				padding: 40px 10px;
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;
				justify-content: center;
			}

			strong {
				text-align: center;
				font-size: 18px;

				&:nth-of-type(2) {
					margin-bottom: 10px;
				}
			}

			ol, ul {
				margin-bottom: 20px;
				display: flex;
				flex-direction: column;
				gap: 10px;
				justify-content: center;

				li {
					display: inline-block;
					text-align: center;
					align-self: center;

					&::before {
						float: left;
						margin-right: 5px;
						content:'';
						display: block;
						width: 20px;
						height: 20px;
						background: url('assets/icons/icon-check.svg') center center no-repeat;
					}
				}
			}

			h3 {
				font-family: 'bold';
				color: var(--color-blue-light);
				font-size: 32px;
				margin-bottom: 20px;
				text-align: center;
			}

			.recommended {
				color: #aaa;
				text-align: center;
			}
		}
	}

	@media screen and (min-width: 768px) {
		margin-bottom: 40px;

		.card-module {
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;

			.card-container {
				padding: 0;
				width: calc(50% - 10px);
				margin-bottom: 20px;
			}
		}
	}

	@media screen and (min-width: 1024px) {
		margin-bottom: 80px;

		.leaf-3 {
			z-index: 0;
			position: absolute;
			background: url(assets/leaf_03.png) no-repeat center center;
			background-size: 100%;
			transform: rotate(-25deg);
			top: -100px;
			right: 30%;
			width: 250px;
			height: 220px;
		}

		.card-module {
			position: relative;
			.card-container {
				width: calc(25% - 15px);

				article {
					position: relative;
					z-index: 1;
					padding-left: 20px;
					padding-right: 20px;
				}

				ol {
					margin-bottom: 40px;
				}

				h3 {
					margin-bottom: 20px;
				}
			}
		}

		header {
			margin-bottom: 40px;
			text-align: center;
			position: relative;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background: var(--color-blue-dark);
				z-index: 0;
				position: absolute;
				left: 0;
				right: 0;
				top: calc(50% - 1px);
			}
		}

		.heading {
			h1, h2, h3 {
				display: inline-block;
				margin: 0;
				padding: 0 20px;
				position:relative;
				z-index: 1;
				font-size: 36px;
				background: var(--color-white);

				em, i, b, span {
					font-size: 36px;
				}
			}

			ol {
				margin-bottom: 60px;
				flex-direction: row;
				gap: 40px;
			}
		}
	}

	@media screen and (min-width: 1440px) {
		header {
			margin-bottom: 80px;
		}

		.heading {
			ol {
				margin-bottom: 80px;
			}
		}
	}
}
