.page-header {
	> img {
		width: 100%;
		height: auto;
	}

	h1 {
		font-size: 28px;
	}

	h2 {
		font-size: 18px;
	}

	h1, h2 {
		text-align: center;
		display: block;
		color: var(--color-white);
	}

	.container {
		display: flex;
		justify-content: center;
		padding-top: 40px;
		padding-bottom: 20px;
		position: relative;
	}

	.badge {
		position: absolute;
		top: -20px;
	}

	@media screen and (min-width: 768px) {
		h1 {
			font-size: 36px;
		}

		h2 {
			font-size: 22px;
		}
	}

	@media screen and (min-width: 1024px) {
		h1 {
			font-size: 56px;
		}

		h2 {
			font-size: 36px;
		}

		.container {
			padding-top: 60px;
			padding-bottom: 40px;
		}
	}
}
