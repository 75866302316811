.blog-page {

	> img {
		width: 100%;
		height: auto;
		display: block;
	}

	header {
		margin-top: 20px;
	}

	h1, h2, h3 {
		font-size: 28px;
		margin-bottom: 20px;
		text-align: center;

		em, i, b, span {
			font-size: 28px;
			color: var(--color-blue-light);
		}
	}

	.container {
		padding: 0;
		overflow: hidden;
		padding-left: 20px;
	}

	.content {
		max-width: 100%;

		display: flex;

		flex-direction: column;
	}

	article {
		margin-bottom: 20px;
		position: relative;

		.badge {
			align-self: start;
			margin-bottom: 20px;
		}

		img {
			width: calc(100% + 20px);
			height: auto;
			border-radius: 8px 0 0 8px;
			margin-bottom: 20px;
		}

		strong {
			font-family: 'light';
			display: block;
			padding-right: 10px;
			font-size: 22px;
			margin-bottom: 10px;
		}

		time {
			margin-bottom: 10px;
			display: block;
		}

		p {
			padding-right: 10px;
			margin-bottom: 20px;
		}

		.thumbnail {
			display: block;
			width: 100%;
		}

		a:not(.thumbnail) {
			margin-right: 10px;
			margin-bottom: 20px;
			display: block;
			text-decoration: underline;
			display: flex;
			color: var(--color-blue-dark);
			gap: 10px;

			.icon-arrow-dark {
				width: 20px;
				height: 20px;
				display: inline-block;
				background: url(assets/icons/icon-arrow-dark.svg) no-repeat center center;
				background-size: 70%;
			}
		}
	}

	@media screen and (min-width: 768px) {

		article {
			img {
				width: 100%;
				height: auto;
				border-radius: 8px;
				margin-bottom: 20px;
			}
		}

		.container {
			margin: auto;
			max-width: 600px;
			padding-left: 20px;
			padding-right: 20px;
		}

	}

	@media screen and (min-width: 1024px) {
		.container {
			max-width: 1440px;
		}

		header {
			margin-top: 80px;
			margin-bottom: 80px;
			text-align: center;
			position: relative;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background: var(--color-blue-dark);
				z-index: 0;
				position: absolute;
				left: 0;
				right: 0;
				top: calc(50% - 1px);
			}
		}

		h1, h2, h3 {
			display: inline-block;
			margin: 0;
			padding: 0 20px;
			position:relative;
			z-index: 1;
			font-size: 36px;
			background: var(--color-white);

			em, i, b, span {
				font-size: 36px;
			}
		}

		article {
			display: flex;
			flex-direction: row;
			gap: 40px;
			align-items: center;
			flex-shrink: 0;

			.thumbnail {
				display: block;
				width: calc(50% - 20px);
			}
		}

		.content {
			width: calc(50% - 20px);
		}
	}

	@media screen and (min-width: 1024px) {
		article {
			gap: 80px;
		}
	}
}


.pagination {
	h2 {
		display: none;
	}

	.nav-links {
		display: flex;
		gap: 10px;
		flex-direction: row;
	}

	.page-numbers {
		margin-bottom: 40px;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--color-gray);
		color: var(--color-black);
		border-radius: 4px;
		transition: 0.25s ease;

		&:hover {
			transition: 0.25s ease;
			background: var(--color-blue-dark);
			color: var(--color-white);
		}

		&.current {
			background: var(--color-blue-dark);
			color: var(--color-white);
		}
	}

	@media screen and (min-width: 1024px) {
		.page-numbers {
			margin-bottom: 80px;
		}
	}
}
