.sensors-gallery {
	padding: 10px 0;

	.container {
		max-width: 600px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 20px;
		padding-left: 0;
		padding-right: 0;
	}

	.slider-container {

		.featuredImage {
			width: 100%;
			height: auto;
		}

		.slider-thumbnails {
			overflow: hidden;
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 10px;

			.slider-thumbnail {
				display: block;
				width: calc(25% - 10px);

				img {
					border-radius: 4px;
					width: 100%;
					height: auto;
				}
			}
		}
	}

	article {
		background: var(--color-gray);
		padding: 20px 10px;
		box-sizing: border-box;
		width: 100%;

		strong {
			display: block;
			color: var(--color-black);
			font-family: 'bold';
			text-align: center;
		}

		h1, h2, h3, h4, h5, h6 {
			display: block;
			text-align: center;
			font-family: 'bold';
			font-size: 32px;
			color: var(--color-blue-light);
			margin-bottom: 20px;
		}

		ol, ul {
			margin-bottom: 20px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			justify-content: center;

			li {
				display: inline-block;
				text-align: center;
				align-self: center;

				&::before {
					float: left;
					margin-right: 5px;
					content:'';
					display: block;
					width: 20px;
					height: 20px;
					background: url('assets/icons/icon-check.svg') center center no-repeat;
				}
			}
		}

		.cta_button {
			background: linear-gradient(45deg, rgba(44,153,200,1) 0%, rgba(40,97,165,1) 100%);
			box-shadow:0 2px 12px rgba(#1C4C94, 0.45);
			color: var(--color-white);
			&:hover {
				transition: background 300ms ease, box-shadow 300ms ease;
				box-shadow: 0 0 0 rgba(0,0,0,0);
				background: linear-gradient(45deg, rgba(44,153,200,1) 30%, rgba(40,97,165,1) 80%);
			}
		}

		p {
			display: block;
			text-align: center;
			margin-bottom: 20px;
		}

		input {
			width: 100%;
			box-sizing: border-box;
			margin-bottom: 20px;
			display: block;
			padding: 10px;
			background: var(--color-white);
			border: solid 2px var(--color-blue-dark);

			&::placeholder {
				color: rgba(0,0,0,0.2);
				text-align: center;
			}

			&:active,
			&:focus {
				border-color: var(--color-blue-light);
			}
		}

		header {
			display: block;
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
			text-align: center;
			font-size: 20px;
			margin-bottom: 20px;
			border-bottom: solid 2px var(--color-blue-dark);
			color: var(--color-blue-dark);
		}
	}

	@media screen and (min-width: 768px) {
		.container {
			margin: 40px auto;
		}

		article {
			border-radius: 8px;
			box-shadow: 0 4px 4px rgba(#2862A5, 0.25);
		}
	}

	@media screen and (min-width: 1024px) {
		overflow-x: hidden;

		.container {
			position: relative;
			z-index: 1;
			max-width: 1440px;
			flex-direction: row;
			gap: 40px;
			padding: 40px 10px;
		}

		.slider-container {
			width: calc(100% - 560px);
			display: flex;
			flex-direction: column;
			gap: 10px;
			justify-content: start;
			align-items: start;

			.featuredImage {
				width: 100%;
			}

			.slider-thumbnails {
				overflow-x: hidden;
				max-width: 100%;
				width: 100%;
				flex-direction: row;

				.slider-thumbnail {
					width: 100%;
					height: auto;

					img {
						min-width: 120px
					}
				}
			}
		}

		article {
			position: relative;
			z-index: 1;
			width: 480px;
			min-width: 480px;
			max-width: 480px;
			padding: 20px;
		}

		.leaf-3 {
			z-index: 0;
			position: absolute;
			background: url(assets/leaf_03.png) no-repeat center center;
			background-size: 75%;
			transform: rotate(-45deg);
			top: -60px;
			right: -160px;
			width: 250px;
			height: 220px;
		}
	}

	@media screen and (min-width: 1440px) {
		.container {
			flex-direction: row;
			gap: 80px;
			padding: 80px 0;
		}
	}
}
