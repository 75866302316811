.hero {
	img {
		width: 100%;
		display: block;
		height: auto;
	}

	.container {
		display: flex;
		justify-content: center;
	}

	.content {
		width: 100%;
		padding: 40px 0 20px 0;
		position: relative;

		.badge {
			position: absolute;
			top: -20px;
		}

		h1 {
			font-size: 22px;
			text-align: center;
			text-transform: uppercase;
			color: var(--color-white);
		}

		h2 {
			font-size: 18px;
			text-align: center;
			text-transform: uppercase;
			color: var(--color-white);
		}
	}


	@media screen and (min-width: 1024px) {
		.content {
			padding: 40px 0;

			h1 {
				font-size: 56px;
			}

			h2 {
				font-size: 36px;
			}
		}
	}
}
