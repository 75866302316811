.single-wiki_post {
	.content {

		table {
			display: block;
			width: 100%;
			margin-bottom: 20px;
			overflow-x: scroll;
			border: solid 2px var(--color-gray);

			td {
				padding: 5px;
			}

			tr:nth-child(2n + 2) {
				background: var(--color-gray);
			}
		}

		table td, table td * {
			vertical-align: top;
		}

		@media screen and (min-width: 1024px) {

		}
	}
}
