#offcanvas-overlay {
	z-index: 1;
	position: absolute;
	top: -200%;
	height: 200%;
	left: 0;
	right: 0;
	background: rgba(#03273B, 0.8);
	backdrop-filter: blur(4px);
	opacity: 0;
	transition: opacity 300ms cubic-bezier(.32, 0, .07, 1), top 0ms 300ms cubic-bezier(.32, 0, .07, 1);

	&.active {
		opacity: 1;
		transition: opacity 300ms cubic-bezier(.32, 0, .07, 1);
		top: 0;
	}
}

nav.main {
	z-index: 2;
	position: fixed;
	padding: 10px;
	height: 60px;
	box-sizing: border-box;
	background: var(--color-white);
	left: 0;
	right: 0;
	top: 0;

	#offcanvas-button {
		width: 30px;
		height: 30px;
		float: right;
		position: fixed;
		top: 15px;
		right: 10px;

		.bar {
			transition: transform 200ms cubic-bezier(.32, 0, .07, 1), top 300ms 200ms cubic-bezier(.32, 0, .07, 1);
			transform-origin: 50% 50%;
			position: absolute;
			width: 30px;
			border-radius: 8px;
			height: 4px;
			transform: rotate(0deg);
			background: var(--color-blue-dark);

			&:nth-child(2) {
				top: 10px;
				transition: opacity 300ms 200ms cubic-bezier(.32, 0, .07, 1);
			}

			&:nth-child(3) {
				top: 20px;
			}
		}

		&.active {


			.bar {
				transition: transform 300ms 200ms cubic-bezier(.32, 0, .07, 1), top 200ms cubic-bezier(.32, 0, .07, 1);

				&:nth-child(1) {
					transform: rotate(45deg);
					top: 10px;
				}

				&:nth-child(2) {
					opacity: 0;
					transition: opacity 300ms 200ms cubic-bezier(.32, 0, .07, 1);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
					top: 10px;
				}
			}
		}
	}

	.button-bar {
		padding: 20px 10px;

		.badge {
			border-radius: 120px;
			text-align: center;
		}

		a {
			width: 100%;
			&:first-child {
				margin-bottom: 20px;
			}
		}
	}

	.brand-logo {
		img {
			height: 40px;
			width: auto;
		}
	}

	#offcanvas {
		z-index: 2;
		overflow-y: scroll;
		position: fixed;
		top: 64px;
		bottom: 0;
		right: -310px;
		width: 300px;
		background: var(--color-white);
		will-change: right;
		opacity: 0;
		transition: right 300ms cubic-bezier(.32, 0, .07, 1), opacity 300ms cubic-bezier(.32, 0, .07, 1);

		&.active {
			opacity: 1;
			transition: right 300ms cubic-bezier(.32, 0, .07, 1), opacity 300ms cubic-bezier(.32, 0, .07, 1);
			right: 0;
		}

		ol {
			li {
				position: relative;

				a, span:first-of-type {
					padding: 15px 20px;
					display: block;
					border-bottom: solid 2px var(--color-gray);
				}

				.icon-arrow-down {
					background: url(assets/icons/icon-arrow-down.svg) no-repeat center center;
					width: 20px;
					height: 20px;
					position: absolute;
					right: 20px;
					top: 15px;
					transition: transform 300ms cubic-bezier(.32, 0, .07, 1);
				}

				li {
					background: var(--color-gray);
				}

				&.active {
					.icon-arrow-down {
						transform: rotate(180deg);
						transition: transform 300ms cubic-bezier(.32, 0, .07, 1);
					}
				}
			}

			ul {
				display: none;
				overflow: hidden;
				height: 0;
				transition: height 300ms cubic-bezier(.32, 0, .07, 1);

				a {
					visibility: hidden;
				}

				&.active {
					display: block;
					a {
						visibility: visible;
					}
					transition: height 300ms cubic-bezier(.32, 0, .07, 1);
				}
			}
		}

	}
}

@media screen and (min-width: 1024px) {
	#offcanvas-overlay {
		display: none;
		visibility: hidden;
	}

	nav.main {
		z-index: 2;
		position: relative;
		padding: 10px 0;
		height: 90px;

		.container {
			display: flex;
			flex-direction: row;
			gap: 40px;
			justify-content: space-between;

			> * {
				align-self: start;
			}
		}

		#offcanvas {
			z-index: 0;
			overflow-y: visible;
			background: transparent;
			position: static;
			top: unset;
			bottom: unset;
			right: unset;
			width: auto;
			will-change: unset;
			opacity: 1;
			display: flex;
			gap: 40px;


			ol {
				padding-top: 10px;
				align-self: start;
				display: flex;
				flex-direction: row;
				gap: 40px;

				li {
					display: inline-block;
					width: auto;
					max-width: min-content;

					&.has-children {
						display: flex;
					}

					a, span:first-of-type {
						display: inline-block;
						padding: 0;
						border: none;
						cursor: pointer;
					}

					.icon-arrow-down {
						position: static;
						right: unset;
						cursor: pointer;
						top: unset;
						display: inline-block;
						height: 24px;
						margin-left: 5px;
					}

					ul {
						display: none;
						position: absolute;
						background: var(--color-white);
						border-radius: 4px;
						min-width: 180px;
						left: 0;
						height: auto;//!important;
						top: 60px;
						visibility: hidden;
						opacity: 0;
						box-shadow:0 4px 12px rgba(#1C4C94, 0.25);
						transition: opacity 300ms cubic-bezier(.32, 0, .07, 1), top 300ms cubic-bezier(.32, 0, .07, 1), visibility 0ms cubic-bezier(.32, 0, .07, 1);

						li {
							display: block;
							max-width: unset;
						}

						a {
							visibility: visible;
							display: block;
							background: var(--color-white);
							padding: 10px;
							border-bottom: solid 2px var(--color-gray);
							transition: background 250ms ease;

							&:hover {
								background: var(--color-gray);
								transition: background 250ms ease;
							}
						}

						&.active {
							display: block;
							height: auto;// !important;
							left: 0;
							opacity: 1;
							visibility: visible;
							top: 40px;
							transition: opacity 300ms cubic-bezier(.32, 0, .07, 1), top 300ms cubic-bezier(.32, 0, .07, 1), visibility 0ms 300ms cubic-bezier(.32, 0, .07, 1), display 0ms 300ms cubic-bezier(.32, 0, .07, 1);
						}
					}
				}
			}
		}

		.button-bar {
			position: relative;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: end;

			a {
				max-width: max-content;
			}

			a:first-child {
				margin: 0;
			}
		}
	}

	#offcanvas-button {
		display: none;
		visibility: hidden;
	}


}
