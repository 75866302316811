.wiki {
	background: var(--color-gray);

	header.main {
		background: var(--color-blue-dark);
		padding: 20px 0;

		h1 {
			color: var(--color-white);
			font-size: 22px;
			text-align: center;
			text-transform: uppercase;
		}
	}

	.wiki-content {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.pagination {
		padding-top: 20px;
	}

	.blogroll {
		.container {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		article {
			box-sizing: border-box;
			background: var(--color-white);
			border-radius: 8px;
			padding: 10px;

		}

		p {
			display: none;
		}
	}

	.back-link {
		background: var(--color-white);
		border-radius: 12px;
		display: block;
		text-decoration: none;
		box-sizing: border-box;
		padding: 10px 15px;
		margin-bottom: 20px;

		&::before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			float: left;
			transform: rotate(180deg);
			margin-right: 10px;
			background: url(assets/icons/icon-arrow-dark.svg) no-repeat center center;
		}
	}

	nav {
		margin-bottom: 20px;

		ol {
			display: flex;
			flex-direction: column;
			gap: 10px;

			> li {
				display: flex;
				justify-content: space-between;
				align-items: center;

				a {
					background: var(--color-white);
					border-radius: 12px;
					display: inline-block;
					text-decoration: none;
					box-sizing: border-box;
					padding: 10px 15px;
					width: calc(100% - 40px);
				}

				ul {
					display: none;
				}
			}
		}

		header {
			h2 {
				font-size: 28px;
				margin-bottom: 20px;
				text-align: center;

				em, i, b, span {
					font-size: 28px;
					color: var(--color-blue-light);
				}
			}
		}
	}

	hr {
		border-top: solid 4px var(--color-blue-dark);
		display: block;
		margin-bottom: 20px;
	}

	.quickview {
		.container {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		article {
			box-sizing: border-box;
			background: var(--color-white);
			border-radius: 8px;
			padding: 10px;

			header {
				font-family: 'bold';
				margin-bottom: 20px;
			}
		}
	}

	strong {
		font-size: 22px;
		margin-bottom: 20px;
		display: block;
	}

	time {
		display: block;
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 10px;
	}

	a {
		display: block;
		text-decoration: underline;
		display: flex;
		color: var(--color-blue-dark);
		gap: 10px;

		.icon-arrow-dark {
			width: 20px;
			height: 20px;
			display: inline-block;
			background: url(assets/icons/icon-arrow-dark.svg) no-repeat center center;
			background-size: 70%;
		}
	}

	@media screen and (min-width: 768px) {
		.container {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		hr {
			margin-bottom: 40px;
		}

		.quickview,
		.blogroll {
			.container {
				flex-direction: row;
				flex-wrap: wrap;
			}

			article {
				padding: 20px;
				width: calc(50% - 10px);
			}

			p {
				display: block;
			}
		}

		.back-link {
			display: inline-block;
		}

		nav {
			ol {
				margin-bottom: 40px;

				flex-direction: row;

				li {
					border-radius: 12px;
					background: var(--color-white);
					box-sizing: border-box;
					padding: 10px;
					width: 25%;

					a {
						padding: 0;
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) {

		header.main {
			h1 {
				color: var(--color-white);
				font-size: 56px;
			}
		}
		.wiki-content {
			padding-top: 80px;
			padding-bottom: 80px;
		}

		hr {
			margin-bottom: 80px;
		}

		.pagination {
			padding-top: 40px;
		}

		.quickview,
		.blogroll {
			article {
				width: calc(33% - 10px);
			}
		}

		nav {
			ol {
				margin-bottom: 80px;
			}

			header {
				margin-bottom: 80px;
				text-align: center;
				position: relative;

				&::after {
					content: '';
					height: 2px;
					width: 100%;
					background: var(--color-blue-dark);
					z-index: 0;
					position: absolute;
					left: 0;
					right: 0;
					top: calc(50% - 1px);
				}

				h2 {
					display: inline-block;
					margin: 0;
					padding: 0 20px;
					position:relative;
					z-index: 1;
					font-size: 36px;
					background: var(--color-gray);

					em, i, b, span {
						font-size: 36px;
					}
				}
			}
		}
	}
}
