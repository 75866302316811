.sensors-teaser {
	background: var(--color-gray);
	padding: 20px 0;

	h1 {
		font-size: 28px;
		margin-bottom: 20px;
		text-align: center;

		em, i, b, span {
			font-size: 28px;
			color: var(--color-blue-light);
		}
	}

	.container {
		padding: 0;
	}

	.content {
		max-width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	article {
		padding-left: 20px;

		img {
			width: calc(100% + 20px);
			height: auto;
			border-radius: 8px 0 0 8px;
			margin-bottom: 20px;
		}

		strong {
			display: block;
			padding-right: 10px;
			font-family: 'bold';
			font-size: 22px;
			margin-bottom: 10px;
		}

		p {
			padding-right: 10px;
			margin-bottom: 20px;
		}

		.btn {
			margin-right: 10px;
			margin-bottom: 20px;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 40px 0;

		article {
			padding-left: 40px;
		}
	}

	@media screen and (min-width: 1024px) {
		padding: 80px 0;

		h1 {
			display: inline-block;
			margin: 0;
			padding: 0 20px;
			position:relative;
			z-index: 1;
			font-size: 36px;
			background: var(--color-gray);

			em, i, b, span {
				font-size: 36px;
			}
		}

		header {
			margin-bottom: 80px;
			text-align: center;
			position: relative;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background: var(--color-blue-dark);
				z-index: 0;
				position: absolute;
				left: 0;
				right: 0;
				top: calc(50% - 1px);
			}
		}


		article {
			padding-left: 0;
			width:33.3%;

			img, p {
				margin-bottom: 40px;
			}

			strong {
				margin-bottom: 20px;
			}
		}

		.container {
			padding-left: 20px;
			padding-right: 20px;
		}

		.content {
			flex-direction: row;
			gap: 40px;

			img {
				width: 100%;
				height: auto;
				border-radius: 8px;
			}

			p, strong {
				padding-right: 0;
			}

			.btn {
				margin: auto auto 20px auto;
				max-width: 80%;
			}
		}
	}

	@media screen and (min-width: 1440px) {

		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
