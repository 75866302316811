.post {
	border-top: solid 20px var(--color-blue-dark);
	padding-top: 20px;
	margin-bottom: 40px;

	h1 {
		margin: 40px 0 10px 0;
		font-size: 28px;
	}

	.stats {
		margin-bottom: 40px;
	}

	img {
		margin-bottom: 40px;
	}

	p {
		margin-bottom: 20px;
	}

	.attachment-post-thumbnail {
		width: 100%;
		height: auto;
		margin: 0;
	}

	ul, li {
		margin-left: 15px;
		list-style: circle;
	}

	em, i {
		color: var(--color-blue-dark);
	}

	strong, b {
		font-family: 'bold';
	}

	a {
		text-decoration: underline;
		color: var(--color-blue-light);
	}

	.btn {
		text-decoration: none;
		color: var(--color-white);
		max-width: 320px;
		margin: 20px auto;
	}

	.cta_button {
		text-decoration: none;
		border-radius: 200px;
		border: none;
		background: linear-gradient(45deg, rgba(44,153,200,1) 0%, rgba(40,97,165,1) 100%);
		box-shadow:0 2px 12px rgba(#1C4C94, 0.45);
		color: var(--color-white);
		padding: 10px 20px;
		box-sizing: border-box;
		transition: background 300ms ease, box-shadow 300ms ease;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		gap: 10px;
		margin-bottom: 20px;

		&:hover {
			transition: background 300ms ease, box-shadow 300ms ease;
			box-shadow: 0 0 0 rgba(0,0,0,0);
			background: linear-gradient(45deg, rgba(44,153,200,1) 30%, rgba(40,97,165,1) 80%);
		}
	}

	.survey {
		background: var(--color-gray);
		padding: 10px;

		.cta_button {
			border: solid 2px var(--color-blue-dark);
			background: transparent;
			box-shadow: none;
			color: var(--color-blue-dark);
			margin-bottom: 0;

			&:hover {
				background: var(--color-white);
			}
		}
	}

	@media screen and (min-width: 1024px) {
		border-top: solid 24px var(--color-blue-dark);
		padding-top: 40px;
		margin-bottom: 80px;

		h1 {
			margin: 80px 0 20px 0;
			font-size: 36px;
		}

		.survey {
			border-radius: 8px;
			display: flex;
			flex-direction: row;
			gap: 20px;

			.hs-cta-wrapper {
				width: calc(50% - 10px)
			}
		}

		.stats {
			margin-bottom: 80px;
		}

		.container {
			max-width: 680px;
		}

		.btn {
			margin: 40px auto;
		}
	}
}
