.forms {
	background: var(--color-gray);
	padding-top: 20px;
	padding-bottom: 20px;

	input, textarea {
		border: solid 1px var(--color-blue-dark);
		background: var(--color-white);
		padding: 10px 15px;
		color: var(--color-blue);
		display: block;
		width: 100%;
		box-sizing: border-box;
		border-radius: 4px;
		margin-bottom: 10px;
	}

	input {
		&::placeholder {
			color: var(--color-gray);
		}
	}

	label {
		margin-bottom: 5px;
		display: block;

		span {
			color: var(--color-blue-dark);
		}

		.hs-form-required {
			color: red;
		}
	}

	textarea {
		min-height: 400px;
	}

	.hs-error-msg {
		color: red;
		font-size: 14px;
	}

	@media screen and (min-width: 768px) {
		padding-top: 40px;
		padding-bottom: 40px;

		.hbspt-form {
			max-width: 800px;
			margin: 0 auto;

			form {
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
			}
		}

		.hs-form-field {
			flex: calc(50% - 10px);
		}

		.hs-fieldtype-textarea {
			flex: 100%;
		}
	}
}

.forms_content {
	background: var(--color-gray);
	padding-top: 20px;
	padding-bottom: 20px;

	.container {
		margin: auto;
	}

	@media screen and (min-width: 768px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}
