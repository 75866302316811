:root {
	--color-blue-dark:#03273B;
	--color-blue-light:#00A3E8;
	--color-green: #87BE43;
	--color-gray: #F0F5F8;
	--color-white: #fff;
	--color-black: #000;
}

@font-face {
	font-family: 'onest';
	src: url('assets/font/onest.woff2') format('woff2'),
			url('assets/font/onest.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-weight: 200 300 400 500 600 700;
	font-variation-settings:
	"wght" 200,
	"wdth" 300,
	"wdth" 400,
	"wdth" 500,
	"wdth" 600,
	"wdth" 700;
}

@font-face {
	font-family: 'bold';
	src: url('assets/font/onest-bold.woff2') format('woff2'),
			url('assets/font/onest-bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'light';
	src: url('assets/font/onest-light.woff2') format('woff2'),
			url('assets/font/onest-light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'regular';
	src: url('assets/font/onest-regular.woff2') format('woff2'),
			url('assets/font/onest-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

* {
	font-family: 'regular';
	margin: 0;
	padding: 0;
	list-style: none;
	font-weight: normal;
	color: var(--color-black);
	font-style: normal;
	outline: none;
	border: none;
	text-decoration: none;
	font-variation-settings: 'wght' 200;
	font-size: 16px;
}

body {
	overflow-x: hidden;
	padding-top: 60px;
}

.btn,
.cta_button,
input[type=submit] {
	border-radius: 200px;
	border: none;
	background: linear-gradient(45deg, rgba(44,153,200,1) 0%, rgba(40,97,165,1) 100%);
	box-shadow:0 2px 12px rgba(#1C4C94, 0.45);
	color: var(--color-white);
	padding: 10px 20px;
	box-sizing: border-box;
	transition: background 300ms ease, box-shadow 300ms ease;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	gap: 10px;

	&:hover {
		transition: background 300ms ease, box-shadow 300ms ease;
		box-shadow: 0 0 0 rgba(0,0,0,0);
		background: linear-gradient(45deg, rgba(44,153,200,1) 30%, rgba(40,97,165,1) 80%);
	}

	.icon-arrow-dark {
		background: url(assets/icons/icon-arrow-dark.svg) no-repeat center center;
		width: 20px;
		height: 20px;
		display: inline-block;
	}

	.icon-arrow-white {
		background: url(assets/icons/icon-arrow-white.svg) no-repeat center center;
		width: 20px;
		height: 20px;
		display: inline-block;
	}

	&.inverted {
		box-shadow: none;
		background: var(--color-white);
		color: var(--color-blue-dark);
		transition: background 300ms ease;

		&:hover {
			background: var(--color-gray);
			transition: background 300ms ease;
		}
	}
}

.cta_button {
	box-shadow: none;
	background: var(--color-white);
	color: var(--color-blue-dark);
	transition: background 300ms ease;

	&:hover {
		background: var(--color-gray);
		transition: background 300ms ease;
	}
}

strong {
	font-family: 'bold';
}

h1, h2, h3 {
	font-family: 'light';
}

.badge {
	display: inline-block;
	border-radius: 200px 0 200px 0;
	background: var(--color-green);
	box-shadow:0 2px 8px rgba(#0C2242, 0.45);
	color: var(--color-white);
	padding: 10px 30px;
	box-sizing: border-box;
}

.container {
	width: 100%;
	box-sizing: border-box;
	max-width: 1440px;
	padding-left: 10px;
	padding-right: 10px;
}

.desktop-only {
	display: none;
}

#hubspot-messages-iframe-container {
	transform: translateY(-50px);
	z-index: 0 !important;
}

.country-selector {
	z-index: 3 !important;
	width: 70px !important;
	position: fixed !important;
	right: 10px;
	bottom: 10px;
}

@media screen and (min-width: 768px) {
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}

	.desktop-only {
		display: block;
	}

	.mobile-only {
		display: none;
	}
}

@media screen and (min-width: 1024px) {
	body {
		padding-top: 0;
	}

	.container {
		padding-left: 0;
		padding-right: 0;
		margin: 0 auto;
	}
}

@media screen and (min-width: 1580px) {
	* {
		font-size: 18px;
	}
}
