.sensors-header {
	display: flex;
	padding-top: 10px;
	justify-content: center;
	background: var(--color-gray);
	position: relative;

	.background {
		position: absolute;
		max-width: 1920px;
		z-index: 0;
		top: 0;
		width: 100%;
		height: auto;
		margin: 0 auto;
	}

	.slider-container {
		z-index: 1;
		position: relative;
	}

	.featuredImage {
		width: 100%;
		height: auto;
		margin-bottom: 20px;
		border-radius: 16px;
	}

	.slider-thumbnails {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 0 10px;
		overflow-y: scroll;
		gap: 20px;
		margin-bottom: 20px;

		img {
			width: 100px;
			height: auto;
			border-radius: 4px;
		}

		.slider-thumbnail {
			opacity: 0.75;

			img {
				display: block;
			}

			&.active {
				opacity: 1;
			}
		}
	}

	.content {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.badge {
			align-self: start;
			margin: auto;
			margin-bottom: 20px;
		}

		p {
			text-align: center;
		}
	}

	@media screen and (min-width: 1024px) {
		padding-top: 80px;

		.slider-container {
			position: relative;
		}

		.slider-thumbnails {
			display: flex;
			position:absolute;
			right: 40px;
			top: 80px;
			overflow: auto;
			flex-direction: column;
			justify-content: space-between;
			padding: 0 10px;

			.slider-thumbnail {
				opacity: 1;
				border-radius: 8px;
				border: solid 6px rgba(0,0,0,0.8);

				&.active {
					border: solid 6px rgba(255,255,255,0.2);
				}
			}
		}

		.content {
			display: block;
			position: absolute;
			top: 40px;
			left: 40px;

			p {
				text-align: left;
				color: var(--color-white);
				max-width: 370px;
			}
		}
	}
}
