.sensors-graphics {
	background: var(--color-gray);
	padding: 20px 0 60px 0;

	h1 {
		font-size: 28px;
		margin-bottom: 20px;
		text-align: center;

		em, i, b, span {
			font-size: 28px;
			color: var(--color-blue-light);
		}
	}

	.flow {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin: auto;

		img {
			width: 100%;
			flex: 100%;
		}
	}
	
	@media screen and (min-width: 768px) {
		padding: 20px 0;

		.flow {
			display: flex;
			flex-direction: row;
			gap: 20px;
			margin: auto;
			padding-left: 10px;
			padding-right: 10px;

			img {
				width: calc(33% - 15px);
				flex: calc(33% - 15px);
			}
		}
	}

	@media screen and (min-width: 1024px) {
		padding: 40px 0;

		h1 {
			display: inline-block;
			margin: 0;
			padding: 0 20px;
			position:relative;
			z-index: 1;
			font-size: 36px;
			background: var(--color-gray);

			em, i, b, span {
				font-size: 36px;
			}
		}

		header {
			margin-bottom: 80px;
			text-align: center;
			position: relative;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background: var(--color-blue-dark);
				z-index: 0;
				position: absolute;
				left: 0;
				right: 0;
				top: calc(50% - 1px);
			}
		}
	}

	@media screen and (min-width: 1440px) {
		padding: 80px 0;

		.flow {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
