.main-content,
.forms_content {
	background: var(--color-gray);
	padding: 20px 0;

	.container {
		max-width: 600px;
		margin: auto;
	}

	h1, h2, h3 {
		font-family: 'bold';
		font-size: 22px;
		margin-bottom: 22px;
		display: block;
	}

	a {
		color: var(--color-blue-light);
	}

	p {
		margin-bottom: 20px;
	}

	@media screen and (min-width: 768px) {
		padding: 40px 0;
	}

	@media screen and (min-width: 1024px) {
		padding: 80px 0;

		h1, h2, h3 {
			font-size: 36px;
		}
	}
}
