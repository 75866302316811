.page-layout {
	.container {
		max-width: 800px;

		p {
			margin-bottom: 20px;
		}

		li {
			list-style: disc;
			margin-left: 15px;
		}

		a {
			text-decoration: underline;
			color: var(--color-blue-light);
		}

		h1, h2, h3, h4, h5, h6 {
			display: block;
			font-family: 'bold';
			font-size: 32px;
			color: var(--color-blue-light);
			margin-bottom: 20px;
		}
	}

	h1 {
		font-size: 28px;
		margin-bottom: 20px;
		text-align: center;

		em, i, b, span {
			font-size: 28px;
			color: var(--color-blue-light);
		}
	}

	@media screen and (min-width: 1024px) {
		padding: 80px 0;

		h1 {
			display: inline-block;
			margin: 0;
			padding: 0 20px;
			position:relative;
			z-index: 1;
			font-size: 36px;
			background: var(--color-white);

			em, i, b, span {
				font-size: 36px;
			}
		}

		header {
			margin-bottom: 80px;
			text-align: center;
			position: relative;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background: var(--color-blue-dark);
				z-index: 0;
				position: absolute;
				left: 0;
				right: 0;
				top: calc(50% - 1px);
			}
		}
	}
}
