footer.main {
	background: var(--color-blue-dark);

	> .container {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	address, nav {
		margin-bottom: 40px;

		strong {
			font-family: 'bold';
		}

		a, p {
			color: var(--color-white);
		}
	}

	nav:last-of-type {
		margin-bottom: 0;
	}

	.icon {
		margin-right: 5px;
		img {
			height: 12px;
			width: auto;
		}
	}

	.brand-logo {
		width: auto;
		margin-bottom: 20px;
		height: 50px;
	}

	a, li, address, strong {
		color: var(--color-white);
	}

	.bottom-bar {
		padding: 20px 0;
		background: #01121C;
		* {
			color: var(--color-white);
		}
	}

	@media screen and (min-width: 768px) {

		> .container {
			justify-content: space-between;
			padding-top: 40px;
			padding-bottom: 40px;
			display: flex;
			flex-direction: row;
			gap: 60px;
		}

		address, nav {
			margin: 0;
		}

		nav {
			padding-top: 30px;
		}
	}

	@media screen and (min-width: 1024px) {
		.container {
			padding-left: 20px;
			padding-right: 10px;
			gap: 80px;
		}
	}

	@media screen and (min-width: 1440px) {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
