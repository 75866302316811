.fifty-fifty {
	max-width: 680px;
	margin: 0 auto;

	.container {
		padding: 0;
	}

	article {
		> img {
			width: 100%;
			height: auto;
			margin-bottom: 20px;
		}
	}

	.content {
		padding: 0 10px;
	}

	h1, h2, h3 {
		font-family: 'bold';
		font-size: 22px;
		margin-bottom: 22px;
		display: block;
	}

	strong {
		display: block;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 40px;
	}

	@media screen and (min-width: 1024px) {
		max-width: unset;

		article {
			padding: 40px 0;
			display: flex;
			flex-direction: row;
			gap: 40px;
			align-items: center;

			&:nth-child(2n + 2) {
				flex-direction: row-reverse;
			}

			> * {
				flex: 50%;
			}

			> img {
				border-radius: 1px;
				margin: 0;
				max-width: 500px;
			}
		}



		h1, h2, h3 {
			font-size: 36px;
		}
	}

	@media screen and (min-width: 1440px) {

		article {
			padding: 80px 0;

			> img {
				max-width: 50%;
			}
		}
	}
}
