.q-and-a:not(.technical) {
	strong {
		display: block;
		margin-bottom: 20px;
		color: var(--color-blue-light);

		&::before {
			content: '';
			float: left;
			margin-right: 10px;
			width: 8px;
			background: var(--color-blue-light);
			height: 15px;
		}
	}
}

.q-and-a {
	padding: 40px 0;

	article {
		margin-bottom: 40px;
		padding-left: 10px;
		padding-right: 10px;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: 'bold';
		display: block;
		margin-bottom: 20px;
	}

	a {
		text-decoration: underline;
		color: var(--color-blue-light);
	}

	@media screen and (min-width: 1024px) {
		.container {
			padding-left: 20px;
			padding-right: 20px;
			display: flex;
			gap: 40px;
			flex-wrap: wrap;
			gap: 40px;
		}

		article {
			padding: 0;
			width: calc(50% - 20px);
		}
	}

	@media screen and (min-width: 1440px) {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
