.cloud-teaser {
	padding-bottom: 20px;
	background: var(--color-blue-dark);

	img {
		width: 100%;
		height: auto;
	}

	.container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.badge {
		position: absolute;
		top: -30px;
	}

	h1, h2 {
		text-transform: uppercase;
		text-align: center;
		color: var(--color-white);
	}

	h1 {
		padding-top: 40px;
		font-size: 22px;
	}

	h2 {
		font-size: 18px;
		margin-bottom: 30px;
	}
}

@media screen and (min-width: 768px) {
	.cloud-teaser {
		padding-bottom: 40px;

		h1 {
			font-size: 36px;
		}

		h2 {
			font-size: 22px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.cloud-teaser {

		h1 {
			font-size: 56px;
		}

		h2 {
			font-size: 36px;
		}
	}
}
