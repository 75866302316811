.frontpage-hero {
	overflow: hidden;
	background: var(--color-blue-dark);
	padding: 20px 0;

	.video-container {
		position: relative;
		margin-bottom: 20px;
		z-index: 1;
	}

	video {
		width: 100%;
		margin: auto;
		max-width: 1440px;
		border-radius: 4px;
	}

	.play-button {
		cursor: pointer;
		z-index: 1;
		border-radius: 900px;
		width: 120px;
		height: 120px;
		background: var(--color-blue-dark);
		opacity: 0.7;
		transition: opacity 200ms ease;
		backdrop-filter: blur(8px);
		position: absolute;
		left: calc(50% - 60px);
		top: calc(50% - 60px);
		display: flex;

		&::before {
			content: '';
			background: url(assets/icons/icon-play.svg) no-repeat center center;
			width: 70px;
			height: 70px;
			position: absolute;
			left: calc(50% - 30px);
			top: calc(50% - 35px);
		}

		&:hover {
			transition: opacity 200ms ease;
			opacity: 0.9;
		}
	}

	h1 {
		font-size: 28px;
		display: block;
		margin-bottom: 10px;
	}

	h2 {
		font-size: 22px;
		display: block;
		margin-bottom: 20px;
	}

	p {
		max-width: 600px;
		margin: 0 auto 20px auto;
	}

	h1, h2, p {
		text-align: center;
		color: var(--color-white);
	}

	.btn,
	.cta_button {
		max-width: 360px;
		margin: 0 auto;
	}

	@media screen and (min-width: 768px) {
		padding: 40px 0;

		.video-container {
			margin-bottom: 40px;
		}
	}

	@media screen and (min-width: 1024px) {
		padding: 80px 0;

		.video-container {
			margin-bottom: 80px;
		}

		video {
			position: relative;
			z-index: 1;
			border-radius: 8px;
		}

		.container {
			position: relative;
		}

		.leaf-2 {
			background: url(assets/leaf_02.png) no-repeat center center;
		}

		.leaf-3 {
			background: url(assets/leaf_03.png) no-repeat center center;
		}

		.leaf-4 {
			background: url(assets/leaf_04.png) no-repeat center center;
		}

		.leaf-5 {
			background: url(assets/leaf_05.png) no-repeat center center;
		}

		.leaf {
			z-index: 0;
			position: absolute;
			background-size: 100%;
			width: 400px;
			height: 300px;
		}

		.first {
			top: -80px;
			right: -160px;
			transform: rotate(-25deg);
		}

		.second {
			top: 80px;
			right: -140px;
			transform: rotate(35deg);
		}

		.third {
			bottom: 260px;
			left: -205px;
			transform: rotate(-165deg);
		}

		.fourth {
			bottom: 130px;
			left: -180px;
			transform: rotate(-215deg);
			background-size: 75%;
		}

	}
}
