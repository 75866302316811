.sensors-blogroll {
	background: var(--color-gray);

	@media screen and (min-width: 1024px) {
		header {
			h1 {
				background: var(--color-gray);
			}
		}
	}
}
