	.listing-blog {
		padding: 20px 0;
		overflow-y: hidden;

		h1, h2, h3 {
			font-size: 28px;
			margin-bottom: 20px;
			text-align: center;

			em, i, b, span {
				font-size: 28px;
				color: var(--color-blue-light);
			}
		}

		.content {
			display: flex;
			overflow-y: scroll;
			gap: 20px;
			flex-direction: row;
		}

		article {
			flex-shrink: 0;
			width: 310px;
			background: var(--color-gray);
			border-radius: 8px;
			overflow: hidden;
			position: relative;
			padding-bottom: 20px;

			img {
				width: 100%;
				height: auto;
				margin-bottom: 20px;
			}

			strong {
				padding: 0 10px;
				font-size: 20px;
				margin-bottom: 10px;
				display: block;
			}

			time {
				padding: 0 10px;
				display: block;
				margin-bottom: 20px;
			}

			a:not(.card-link) {
				padding: 0 10px;
				display: block;
				text-decoration: underline;
				display: flex;
				color: var(--color-blue-dark);
				gap: 10px;

				.icon-arrow-dark {
					width: 20px;
					height: 20px;
					display: inline-block;
					background: url(assets/icons/icon-arrow-dark.svg) no-repeat center center;
					background-size: 70%;
				}
			}

			.card-link {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
			}
		}


		@media screen and (min-width: 768px) {
			padding: 40px 0;

			.container {
				padding-left: 20px;
				padding-right: 20px;
			}

		}

		@media screen and (min-width: 1024px) {
			padding: 80px 0;

			header {
				margin-bottom: 80px;
				text-align: center;
				position: relative;

				&::after {
					content: '';
					height: 2px;
					width: 100%;
					background: var(--color-blue-dark);
					z-index: 0;
					position: absolute;
					left: 0;
					right: 0;
					top: calc(50% - 1px);
				}
			}

			h1, h2, h3 {
				display: inline-block;
				margin: 0;
				padding: 0 20px;
				position:relative;
				z-index: 1;
				font-size: 36px;
				background: var(--color-white);

				em, i, b, span {
					font-size: 36px;
				}
			}

			.content {
				gap: 40;
				overflow-y: hidden;
			}

			article {
				flex-shrink: 1;
				width: auto;

				a:not(.card-link), time, strong {
					padding: 0 20px;
				}
			}


		}

		@media screen and (min-width: 1440px) {

			article {
				padding-bottom: 40px;

				a:not(.card-link), time, strong {
					padding: 0 40px;
				}
			}
		}
	}
