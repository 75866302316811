.contact {
	padding: 20px 0;
	background: var(--color-gray);

	.contact-card {
		background: linear-gradient(45deg, #07264A, #2C99C8);
		border-radius:8px;
		padding: 10px;

		img {
			width: 100%;
			height: auto;
			margin-bottom: 10px;
			border-radius: 4px;
		}

		.content {
			color: var(--color-white);
		}

		strong {
			color: var(--color-white);
		}

		h3 {
			font-family: 'bold';
			font-size: 22px;
		}

		h3, a:not(.cta_button) {
			color: var(--color-white);
		}
	}
	.faq {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.badge {
			align-self: start;
			margin: 20px auto;
		}

		.accordion {
			border-radius: 8px;
			border: solid 2px var(--color-blue-light);
			background: var(--color-white);
			padding: 20px;
			margin-bottom: 20px;
			color: var(--color-blue-light);

			strong, p, header {
				color: var(--color-blue-light);
			}

			header {
				cursor: pointer;
				font-family: 'bold';
				position: relative;
				padding-right: 30px;
				transition: margin 200ms 200ms cubic-bezier(.32, 0, .07, 1);

				&::before {
					content: '';
					background: url('assets/icons/icon-plus.svg') no-repeat center center;
					display: block;
					position: absolute;
					right: 0;
					cursor: pointer;
					width: 18px;
					height: 18px;
					top: calc(50% - 9px);
				}
			}

			.content {
				overflow: hidden;
				height: 0;
				transition: height 300ms cubic-bezier(.32, 0, .07, 1);
			}

			&.active {
				header {
					margin-bottom: 20px;
					transition: margin 300ms cubic-bezier(.32, 0, .07, 1);
				}
			}
		}
	}

	@media screen and (min-width: 768px) {
		overflow: hidden;
		padding: 40px 0;
		background: transparent;

		.container {
			z-index: 0;
			position: relative;
			display: flex;
			flex-direction: row;
			gap: 40px;
			padding: 40px 10px;

			.faq, .contact-card {
				align-self: start;
				position: relative;
				width: calc(50% - 20px);
			}

			.faq, .contact-card {
				z-index: 2;
			}

			.faq {
				.badge {
					position: absolute;
					margin: 0;
					top: -60px;
					right: 0;
				}
			}
		}

		.leaf {
			z-index: 1;
			position: absolute;
		}

		.background {
			z-index: 0;
			position: absolute;
			background: var(--color-gray);
			top: 0;
			bottom: 0;
			border-radius: 8px;
			left: 120px;
			right: -40px;
		}
	}

	@media screen and (min-width: 1024px) {
		padding: 80px 0;

		.contact-card {
			padding: 0;
			display: flex;
			gap: 20px;
			align-items: center;

			img {
				width: 50%;
				margin: 0;
			}

			.content {
				padding: 10px 20px;
			}
		}

		.container {
			gap: 80px;
			padding-left: 20px;
			padding-right: 20px;

			.faq, .contact-card {
				width: calc(50% - 40px);
			}
		}

		.leaf-5 {
			background: url(assets/leaf_05.png) no-repeat center center;
			background-size: 75%;
			transform: rotate(-150deg);
			top: -50px;
			left: 10px;
			width: 250px;
			height: 220px;
		}

		.leaf-4 {
			background: url(assets/leaf_04.png) no-repeat center center;
			background-size: 100%;
			transform: rotate(-25deg);
			top: 100px;
			right: -80px;
			width: 250px;
			height: 220px;
		}

		.leaf-1 {
			background: url(assets/leaf_01.png) no-repeat center center;
			background-size: 75%;
			transform: rotate(15deg);
			top: 200px;
			right: -80px;
			width: 250px;
			height: 220px;
		}
	}

	@media screen and (min-width: 1440px) {
		.contact-card {
			gap: 40px;

			img {
				width: 100%;
			}

			h3 {
				font-size: 24px;
			}

			.content {
				padding: 0 80px 0 0;
			}
		}

		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
