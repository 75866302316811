.slider {
	display: flex;
	flex-direction: column-reverse;

	.slider-container {
		padding: 10px;
		margin: 0 auto;

		img {
			width: 100%;
			display: block;
		}

		.slider-featuredImage {
			border-radius: 1px;
		}

		.slider-thumbnails {
			display: flex;
			width: 100%;
			justify-content: center;
			margin: 10px 0;
			gap: 10px;
		}

		.slider-thumbnail img {
			max-width: 80px;
			min-width: 25%;
			border-radius: 8px;
		}

		.slider-thumbnail {
			opacity: 0.7;
			cursor: pointer;

			&.active {
				opacity: 1;
			}
		}

		.slider-thumbnail:not(:first-of-type) {
			margin-left: 10px;
		}
	}

	@media screen and (min-width: 768px) {
		.slider-container {
			padding: 20px 0;
		}
	}

	@media screen and (min-width: 1024px) {
		p {
			margin-bottom: 40px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
